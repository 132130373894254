// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-standard-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Standard.jsx" /* webpackChunkName: "component---src-templates-standard-jsx" */),
  "component---src-templates-issue-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Issue.jsx" /* webpackChunkName: "component---src-templates-issue-jsx" */),
  "component---src-templates-article-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-archive-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-keywords-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Keywords.jsx" /* webpackChunkName: "component---src-templates-keywords-jsx" */),
  "component---src-templates-authors-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Authors.jsx" /* webpackChunkName: "component---src-templates-authors-jsx" */),
  "component---src-templates-reviewers-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Reviewers.jsx" /* webpackChunkName: "component---src-templates-reviewers-jsx" */),
  "component---src-templates-search-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-search-by-author-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\SearchByAuthor.jsx" /* webpackChunkName: "component---src-templates-search-by-author-jsx" */),
  "component---src-templates-search-by-keyword-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\templates\\SearchByKeyword.jsx" /* webpackChunkName: "component---src-templates-search-by-keyword-jsx" */),
  "component---src-pages-index-jsx": () => import("F:\\Sources\\linguistische-treffen\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "F:\\Sources\\linguistische-treffen\\.cache\\data.json")

